<template>
  <div id="dynamic-table-header">
    <div
      class="table-header-customizator toggle-button mx-5 py-2 px-3"
      v-b-toggle.table-header-customizator
    >
      <p class="m-0">
        Customize table header
        <font-awesome-icon :icon="['fas', 'sort-down']" class="fa-lg float-right" />
      </p>
    </div>

    <b-collapse id="table-header-customizator" class="table-header-customizator mx-5 p-3">
      <b-button
        class="m-1 btn-sm"
        v-for="(value, key) in allFields"
        :key="key"
        :variant="isTableField(key) > -1 ? 'primary' : 'outline-secondary'"
        @click="toggleTableField(value, key)"
        >{{ value.label }}</b-button
      >
    </b-collapse>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#dynamic-table-header {
  .table-header-customizator {
    background-color: $color-grey-lighter4;
    &.toggle-button:hover {
      background-color: $color-grey-lighter6;
    }
    &#table-header-customizator {
      display: flex;
      flex-wrap: wrap;
      & > button {
        flex: 0 0 12%;
      }
    }
  }
}
</style>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "DynamicTableHeader",
  props: {
    allFields: Object, //table fields format
    defaultFields: Array, //array of field names
    fields: Array //fields the table starts with -> action button / empty array
  },
  data() {
    return {
      selectedFields: []
    };
  },
  created() {
    this.addDefaultFields();
  },
  methods: {
    isEmpty: FormatHelper.isEmpty,

    addDefaultFields() {
      if (!this.isEmpty(this.defaultFields)) {
        if (!this.isEmpty(this.fields)) {
          this.selectedFields = this.fields;
        }

        this.defaultFields.forEach((fieldName) => {
          let tableValue = Object.assign({}, this.allFields[fieldName]);
          tableValue.sortable = true;
          tableValue.key = fieldName;
          this.selectedFields.push(tableValue);
        });

        this.$emit("updated-fields", this.selectedFields);
      }
    },

    isTableField(value) {
      if (!this.isEmpty(this.selectedFields)) {
        return this.selectedFields.findIndex((f) => f.key == value);
      } else return -1;
    },

    toggleTableField(value, key) {
      let tableValue = Object.assign({}, value);
      tableValue.key = key;
      tableValue.sortable = true;

      let index = this.isTableField(key);
      if (index > -1) {
        this.selectedFields.splice(index, 1);
      } else {
        this.selectedFields.push(tableValue);
      }

      this.$emit("updated-fields", this.selectedFields);
    }
  }
};
</script>